import { CommonModule, DatePipe } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { AbpModule } from 'abp-ng2-module';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { AbpPaginationControlsComponent } from './pagination/abp-pagination-controls.component';
import { LocalizePipe } from './pipes/localize.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

import { BlockDirective } from './directives/block.directive';
import { BusyDirective } from './directives/busy.directive';
import { UtcToLocalTimePipe } from './pipes/utc-to-local-time.pipe';
@NgModule({
    imports: [
        CommonModule,
        AbpModule,
        RouterModule,
        NgxPaginationModule,
    ],
    declarations: [
        AbpPaginationControlsComponent,
        LocalizePipe,
        BlockDirective,
        BusyDirective,
        UtcToLocalTimePipe],
    exports: [
        AbpPaginationControlsComponent,
        LocalizePipe,
        BlockDirective,
        BusyDirective,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        ScrollingModule,
        UtcToLocalTimePipe
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                DatePipe
            ]
        };
    }
}
