import { AbpMultiTenancyService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import {
    ApplicationInfoDto,
    GetCurrentLoginInformationsOutput,
    SessionServiceProxy,
    TenantLoginInfoDto,
    UserLoginInfoDto
} from '../service-proxies/service-proxies';



@Injectable()


export class AppSessionService {



    private _user: UserLoginInfoDto;


    private _tenant: TenantLoginInfoDto;


    private _application: ApplicationInfoDto;





    constructor(


        private _sessionService: SessionServiceProxy,


        private _abpMultiTenancyService: AbpMultiTenancyService) {


    }



    get application(): ApplicationInfoDto {


        return this._application;


    }



    set application(val: ApplicationInfoDto) {


        this._application = val;


    }



    get user(): UserLoginInfoDto {


        return this._user;


    }



    get userId(): number {


        return this.user ? this.user.id : null;


    }



    get tenant(): TenantLoginInfoDto {


        return this._tenant;


    }



    get tenancyName(): string {


        return this._tenant ? this.tenant.tenancyName : '';


    }



    get tenantId(): number {


        return this.tenant ? this.tenant.id : null;


    }



    getShownLoginName(): string {


        const userName = this._user.userName;


        if (!this._abpMultiTenancyService.isEnabled) {


            return userName;


        }



        return (this._tenant ? this._tenant.tenancyName : '.') + '\\' + userName;


    }






    init(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if(abp.utils.getCookieValue('Abp.Localization.CultureName') == null)
            {
                abp.utils.setCookieValue(
                    'Abp.Localization.CultureName',
                    "en",
                    new Date(new Date().getTime() + 5 * 365 * 86400000), // 5 year
                    abp.appPath
                );
            }
            this._sessionService.getCurrentLoginInformations(abp.utils.getCookieValue('Abp.Localization.CultureName')).toPromise().then((result: GetCurrentLoginInformationsOutput) => {
                this._application = result.application;
                this._user = result.user;
                this._tenant = result.tenant;

                resolve(true);
            }, (err) => {
                reject(err);
            });
        });
    }


    changeTenantIfNeeded(tenantId?: number): boolean {


        if (this.isCurrentTenant(tenantId)) {


            return false;


        }



        abp.multiTenancy.setTenantIdCookie(tenantId);


        location.reload();


        return true;


    }



    private isCurrentTenant(tenantId?: number) {


        let isTenant = tenantId > 0;



        if (!isTenant && !this.tenant) { // this is host


            return true;


        }



        if (!tenantId && this.tenant) {


            return false;


        } else if (tenantId && (!this.tenant || this.tenant.id !== tenantId)) {


            return false;


        }



        return true;


    }


}
