import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.OwnerServiceProxy,
        ApiServiceProxies.LeadServiceProxy,
        ApiServiceProxies.CountryServiceProxy,
        ApiServiceProxies.CityServiceProxy,
        ApiServiceProxies.DistrictServiceProxy,
        ApiServiceProxies.PropertyuseServiceProxy,
        ApiServiceProxies.PropertytypeServiceProxy,
        ApiServiceProxies.PropertyoptionServiceProxy,
        ApiServiceProxies.CategoryServiceProxy,
        ApiServiceProxies.PurposeServiceProxy,
        ApiServiceProxies.UnitstatusServiceProxy,
        ApiServiceProxies.SeekerServiceProxy,
        ApiServiceProxies.PropertyCostServiceProxy,
        ApiServiceProxies.PropertyCostAttachmentServiceProxy,
        ApiServiceProxies.OwnerTransactionServiceProxy,
        ApiServiceProxies.OwnerTransactionAttachmentServiceProxy,
        ApiServiceProxies.PotentialOwnerServiceProxy,
        ApiServiceProxies.ContactUsApplicationServiceProxy,
        ApiServiceProxies.OwnerBalanceServiceProxy,
        ApiServiceProxies.OwnerBalanceAttachmentServiceProxy,
        ApiServiceProxies.OfficeGLServiceProxy ,
        ApiServiceProxies.PropertyGLServiceProxy,
        ApiServiceProxies.TemplateGLServiceProxy,
        ApiServiceProxies.CountryGLServiceProxy,
        ApiServiceProxies.ContractGLServiceProxy,
        ApiServiceProxies.UsersGLServiceProxy,
        ApiServiceProxies.UnitGLServiceProxy,        
        ApiServiceProxies.NationalityServiceProxy,
        ApiServiceProxies.PropertyServiceProxy,
        ApiServiceProxies.TemplateServiceProxy,
        ApiServiceProxies.UnitServiceProxy,
        ApiServiceProxies.TermServiceProxy,
        ApiServiceProxies.ContractServiceProxy,
        ApiServiceProxies.ContractlineServiceProxy,
        ApiServiceProxies.InstalmentServiceProxy,
        ApiServiceProxies.BillServiceProxy,
        ApiServiceProxies.UnitattachmentServiceProxy,
        ApiServiceProxies.CollectionServiceProxy,
        ApiServiceProxies.CreditnoteServiceProxy,
        ApiServiceProxies.VisitrequestServiceProxy,
        ApiServiceProxies.MaintenancerequestServiceProxy,
        ApiServiceProxies.CurrencyServiceProxy,
        ApiServiceProxies.MaintenancecategoryServiceProxy,
        ApiServiceProxies.MaintenancesubcategoryServiceProxy,
        ApiServiceProxies.OperationCompanyServiceProxy,
        ApiServiceProxies.OperationCompanyAttachmentServiceProxy,
        ApiServiceProxies.OperationCompanyGLServiceProxy,
        ApiServiceProxies.EjarContractAttachmentServiceProxy ,
        /* Define Your Custom Service Here */

        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
