import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { RootModule } from './root.module';
import { hmrBootstrap } from './hmr';
import { environment } from './environments/environment';
import * as moment from "moment";
import 'moment/min/locales.min';
import 'moment-timezone';
import 'hammerjs';




if (environment.production) {
  enableProdMode();
}

const bootstrap = () => {
  return platformBrowserDynamic().bootstrapModule(RootModule);
};


  if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap); // HMR enabled bootstrap
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
    }
} else {
    bootstrap(); // Regular bootstrap
}
