import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRouteGuard } from './shared/auth/auth-route-guard';

const routes: Routes = [
    { path: '', redirectTo: 'app', pathMatch: 'full' },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        data: { preload: true }
    },
    {
        path: 'website',
        loadChildren: () => import('./website/website.module').then(m => m.WebsiteModule),
        data: { preload: true }
    },
    {
        path: 'app',
        canActivate: [AppRouteGuard],
        loadChildren: () => import('./app/app.module').then(m => m.AppModule),
        data: { preload: true }
    },
    { path: '**', redirectTo:'app/404/' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes )],
    exports: [RouterModule],
    providers: []
})
export class RootRoutingModule { }
