export class AppConsts {
    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';
    static remoteServiceBaseUrl: string;
    static appBaseUrlWithPlaceHolder: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Shore'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };
    static recaptchaSiteKey: string = "Recaptcha.SiteKey";




    static remoteServiceBaseUrlFormat: string;

    static appBaseUrlFormat: string;
    static subscriptionExpireNootifyDayCount: number;
    
    

    static readonly grid = {
        defaultPageSize: 10
    };

    
    }
    





