import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { UtcToLocalTimeFormat } from "./UtcToLocalTimeFormat";

@Pipe({
    name: 'utcToLocalTime',
})

export class UtcToLocalTimePipe   implements PipeTransform {

    constructor(
        public datepipe: DatePipe,
    ){}


    transform(utcDate: any,    // UTC ISO-8601
        format: UtcToLocalTimeFormat = UtcToLocalTimeFormat.FULL
    ): string {
        var browserLanguage = navigator.language;
        let mainUtc = utcDate._i + 'Z'
        if (format === UtcToLocalTimeFormat.FULL_DATE_ONLY) {
           return this.datepipe.transform(mainUtc, 'EEEE, MMMM d, y');
        }
        else if (format === UtcToLocalTimeFormat.SHORT) {
            let date = new Date(mainUtc).toLocaleDateString(browserLanguage);
            let time = new Date(mainUtc).toLocaleTimeString(browserLanguage);

            return `${date}, ${time}`;
        }
        else if (format === UtcToLocalTimeFormat.SHORT_DATE) {
            return new Date(mainUtc).toLocaleDateString(browserLanguage);
        }
        else if (format === UtcToLocalTimeFormat.SHORT_TIME) {
            return this.datepipe.transform(mainUtc, 'h:mm:ss a',);
        }
        else if (format === UtcToLocalTimeFormat.FULL) {
            return new Date(mainUtc).toString();
        }
        else if(format===UtcToLocalTimeFormat.MEDIUM_DATE){
            return this.datepipe.transform(mainUtc, 'MMM d, y');
        }
        else if(format===UtcToLocalTimeFormat.SHORT_DATE_DMY){
            return this.datepipe.transform(mainUtc, 'dd/MM/yyy');
        }
        else if(format===UtcToLocalTimeFormat.MEDIUM_DATE_MDY){
            return this.datepipe.transform(mainUtc, 'dd-MMM-yyy');
        }
        else if(format===UtcToLocalTimeFormat.MEDIUM_DATE_DATETIME){  
            let date = this.datepipe.transform(mainUtc, 'dd-MMM-yyy h:mm:ss a');
            return date;
        }
        else if(format===UtcToLocalTimeFormat.LONG_MONTH_SHORT_DAY){
            let date = this.datepipe.transform(mainUtc, 'MMM, yyy');
           return date;
        }
        else if(format===UtcToLocalTimeFormat.SHORT_DATE_MDY){
            let date = this.datepipe.transform(mainUtc, 'MM/dd/yyy');
           return date;
        }
        
        else {
            console.error(
                `Do not have logic to format utc date, format:${format}`
            );
            return new Date(mainUtc).toString();
        }
    }

    public convertLocalTimeToUtc(localDate: string): string {
        var date = new Date(localDate);
        return date.toUTCString();

    }
}

