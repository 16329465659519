export enum UtcToLocalTimeFormat{
    FULL        = 'full',        // 'EEEE, MMMM d, y, h:mm:ss a zzzz'   - Monday, June 15, 2015 at 9:03:01 AM GMT+01:00
    FULL_DATE_ONLY = 'fullDateOnly',        // 'EEEE, MMMM d, y'   - Monday, June 15, 2015
    SHORT       = 'short',       // 'd/M/yy, h:mm'                      - 15/6/15, 9:03
    SHORT_DATE  = 'shortDate',   // 'd/M/yy'                            - 15/6/15
    SHORT_TIME  = 'shortTime',   // 'h:mm'                              - 9:03
    MEDIUM_DATE='mediumDate',     //'MMM d, y'                          -Jun 15, 2015
    SHORT_DATE_DMY='shortDateDMY',     //'dd/MM/yyy'                    - 6/15/15
    MEDIUM_DATE_MDY='mediumDateMDY',     //'dd-MMM-yyy'                  -15-Jun-2015
    MEDIUM_DATE_DATETIME='mediumDateDateTime',     //'dd-MMM-yyy 00:00:00 AM'        -21-Sep-2022 1:16:00 PM
    LONG_MONTH_SHORT_DAY='lONGMONTHSHORTDAY',     //'MMM,yyy'        -Aug, 2021
    SHORT_DATE_MDY='shortDateMDY'  //'MM/dd/yyy'  

}