import { AppConsts } from './shared/AppConsts';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Type, CompilerOptions, NgModuleRef, Injector } from '@angular/core';
import { environment } from './environments/environment';
import { UtilsService } from 'abp-ng2-module';
import { LocalizedResourcesHelper } from './shared/helpers/LocalizedResourcesHelper';
import { UrlHelper } from './shared/helpers/UrlHelper';
import { AppAuthService } from './shared/auth/app-auth.service';
import * as rtlDetect from 'rtl-detect';
import { XmlHttpRequestHelper } from './shared/helpers/XmlHttpRequestHelper';
import { LocaleMappingService } from './shared/locale-mapping.service';
import "moment-timezone";
import * as moment from "moment";
import { SubdomainTenancyNameFinder } from './shared/helpers/SubdomainTenancyNameFinder';
import { AccountServiceProxy, IsTenantAvailableInput, IsTenantAvailableOutput, TenantAvailabilityState } from './shared/service-proxies/service-proxies';


export class AppPreBootstrap {

    static run(appRootUrl: string,injector: Injector, callback: () => void): void {
        AppPreBootstrap.getApplicationConfig(appRootUrl, injector, (tenancyName: string) => {
            

            const queryStringObj = UrlHelper.getQueryParameters();
            if (queryStringObj.redirect && queryStringObj.redirect === 'TenantRegistration') {
                if (queryStringObj.forceNewRegistration) {
                    new AppAuthService().logout();
                }

                location.href = AppConsts.appBaseUrl + '/account/select-edition';
            } else if (queryStringObj.impersonationToken) {
                AppPreBootstrap.impersonatedAuthenticate(queryStringObj.impersonationToken, queryStringObj.tenantId, () => { AppPreBootstrap.getUserConfiguration(callback); });
            } else if (queryStringObj.switchAccountToken) {
                AppPreBootstrap.linkedAccountAuthenticate(queryStringObj.switchAccountToken, queryStringObj.tenantId, () => { AppPreBootstrap.getUserConfiguration(callback); });
            } else {
                AppPreBootstrap.getUserConfiguration(callback);
            }
        });
    }
    private static linkedAccountAuthenticate(switchAccountToken: string, tenantId: number, callback: () => void): JQueryPromise<any> {
        abp.multiTenancy.setTenantIdCookie(tenantId);
        const cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');
        return abp.ajax({
            url: AppConsts.remoteServiceBaseUrl + '/api/TokenAuth/LinkedAccountAuthenticate?switchAccountToken=' + switchAccountToken,
            method: 'POST',
            headers: {
                '.AspNetCore.Culture': ('c=' + cookieLangValue + '|uic=' + cookieLangValue),
                'Abp-TenantId': abp.multiTenancy.getTenantIdCookie()
            }
        }).done(result => {
            abp.auth.setToken(result.accessToken);
            AppPreBootstrap.setEncryptedTokenCookie(result.encryptedAccessToken);
            location.search = '';
            callback();
        });
    }

    static bootstrap<TM>(moduleType: Type<TM>, compilerOptions?: CompilerOptions | CompilerOptions[]): Promise<NgModuleRef<TM>> {
        return platformBrowserDynamic().bootstrapModule(moduleType, compilerOptions);
    }

    private static getApplicationConfig(appRootUrl: string,
        injector: Injector,
        callback: (tenancyName) => void) {

        

        return abp.ajax({
            url: appRootUrl + 'assets/' + environment.appConfig,
            method: 'GET',
            headers: {
                'Abp-TenantId': abp.multiTenancy.getTenantIdCookie()
            }
        }).done(result => {
            
            const subdomainTenancyNameFinder = new SubdomainTenancyNameFinder();
            const tenancyName = subdomainTenancyNameFinder.getCurrentTenancyNameOrNull(result.appBaseUrl);
            
            AppConsts.appBaseUrlWithPlaceHolder = result.appBaseUrlWithPlaceHolder;
            AppConsts.appBaseUrlFormat = result.appBaseUrl;
			AppConsts.remoteServiceBaseUrlFormat = result.remoteServiceBaseUrl;
            AppConsts.localeMappings = result.localeMappings;
            if (tenancyName == null) {
				AppConsts.appBaseUrl = result.appBaseUrl.replace(
					AppConsts.tenancyNamePlaceHolderInUrl + '.',
					''
				);
				AppConsts.remoteServiceBaseUrl = result.remoteServiceBaseUrl.replace(
					AppConsts.tenancyNamePlaceHolderInUrl + '.',
					''
				);

				callback(tenancyName);
			} else {
                
				AppConsts.appBaseUrl = result.appBaseUrl.replace(
					AppConsts.tenancyNamePlaceHolderInUrl,
					tenancyName
				);
				AppConsts.remoteServiceBaseUrl = result.remoteServiceBaseUrl.replace(
					AppConsts.tenancyNamePlaceHolderInUrl,
					tenancyName
				);

				if (!subdomainTenancyNameFinder.urlHasTenancyNamePlaceholder(result.remoteServiceBaseUrl)) {
					let accountServiceProxy: AccountServiceProxy = injector.get(AccountServiceProxy);
					let input = new IsTenantAvailableInput();
					input.tenancyName = tenancyName;

					accountServiceProxy
						.isTenantAvailable("1.0",input)
						.subscribe((result: IsTenantAvailableOutput) => {
							if (result.state === TenantAvailabilityState.Available) {
								abp.multiTenancy.setTenantIdCookie(result.tenantId);
							}

							callback(tenancyName);
						});
				} else {
					callback(tenancyName);
				}
			}
        });
    }

    private static getCurrentClockProvider(currentProviderName: string): abp.timing.IClockProvider {
        if (currentProviderName === 'unspecifiedClockProvider') {
            return abp.timing.unspecifiedClockProvider;
        }
        if (currentProviderName === 'utcClockProvider') {
            return abp.timing.utcClockProvider;
        }
        return abp.timing.localClockProvider;
    }


    private static setEncryptedTokenCookie(encryptedToken: string) {
        new UtilsService().setCookieValue(AppConsts.authorization.encrptedAuthTokenName,
            encryptedToken,
            new Date(new Date().getTime() + 365 * 86400000), //1 year
            abp.appPath
        );
    }
    private static impersonatedAuthenticate(impersonationToken: string, tenantId: number, callback: () => void): JQueryPromise<any> {
        abp.multiTenancy.setTenantIdCookie(tenantId);
        const cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');
        return abp.ajax({
            url: AppConsts.remoteServiceBaseUrl + '/api/TokenAuth/ImpersonatedAuthenticate?impersonationToken=' + impersonationToken,
            method: 'POST',
            headers: {
                '.AspNetCore.Culture': ('c=' + cookieLangValue + '|uic=' + cookieLangValue),
                'Abp-TenantId': abp.multiTenancy.getTenantIdCookie()
            }
        }).done(result => {
            abp.auth.setToken(result.accessToken);
            AppPreBootstrap.setEncryptedTokenCookie(result.encryptedAccessToken);
            location.search = '';
            callback();
        });
    }

    private static getUserConfiguration(callback: () => void): JQueryPromise<any> {
        const token = abp.auth.getToken();
        let requestHeaders = {
            '.AspNetCore.Culture': abp.utils.getCookieValue('Abp.Localization.CultureName'),
            'Abp-TenantId': abp.multiTenancy.getTenantIdCookie()
        };

        if (token) {
        requestHeaders['Authorization'] = 'Bearer ' + token;
        }
        return abp.ajax({
            url: AppConsts.remoteServiceBaseUrl + '/AbpUserConfiguration/GetAll',
            method: 'GET',
            headers: requestHeaders
        }).done(result => {
            $.extend(true, abp, result);
            abp.clock.provider = this.getCurrentClockProvider(result.clock.provider);
            
            moment.locale(abp.localization.currentLanguage.name);
            const currentCulture = abp.localization.currentLanguage.name;
            const isRtl = rtlDetect.isRtlLang(currentCulture);
            if (isRtl) {
                $('html').attr('dir', 'rtl');
            }
            if (abp.clock.provider.supportsMultipleTimezone) {
                moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
            }
            AppConsts.recaptchaSiteKey = abp.setting.get('Recaptcha.SiteKey');

            callback();
        });
    }
}

